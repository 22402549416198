@charset "UTF-8";
/**
 thin 100
 extra-light 200
 light 300
 regular 400
 medium 500
 semi-bold 600
 bold 700
 extra-bold 800
 black, heavy 900
 */
/*
@font-face {
  font-family: "Font";
  src:
    local("☺"),
    url("../assets/fonts//font.woff2") format("woff2"),
    url("../assets/fonts//font.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
*/
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: 100%;
}

body {
  margin: 0;
  height: 100%;
  cursor: default;
  font-size: 14px;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: none;
  background: #fff;
  color: #000;
}

a,
area,
button,
[role=button],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

::selection {
  background: #000;
  color: #fff;
}

.color-secondary {
  color: #3063a6 !important;
}

.font-weight-thin {
  font-weight: 100 !important;
}

.font-weight-extra-light {
  font-weight: 200 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-extra-bold {
  font-weight: 800 !important;
}

.font-weight-black {
  font-weight: 900 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.client-block {
  display: block;
  text-decoration: none;
}
.client-block:hover .client-block__image {
  transform: scale(1.05);
}
.client-block__image {
  max-width: 110px;
  max-height: 42px;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  transition: 0.15s transform ease-in-out;
}
@media (min-width: 560px) {
  .client-block__image {
    max-width: 270px;
    max-height: 100px;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 100%;
}
@media (min-width: 560px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1330px;
  }
}

.footer {
  padding: 36px 0 50px;
  background: #1475f3;
}
.footer__logo {
  display: block;
  text-decoration: none;
  width: 121px;
  height: 30px;
}
.footer__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (min-width: 768px) {
  .footer__logo {
    width: 242px;
    height: 59px;
  }
}
@media (max-width: 991.98px) {
  .footer__logo {
    margin-top: 16px;
  }
}
.footer__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.15em;
  color: #eaefff;
  transition: 0.1s opacity ease-in-out;
}
.footer__link:hover {
  opacity: 0.75;
}
.footer__link-icon {
  width: 20px;
  height: 20px;
  fill: currentColor;
  margin-right: 24px;
}
.footer__link + .footer__link {
  margin-top: 16px;
}
.footer__link-presentation {
  font-size: 13px;
  padding-top: 10px;
}
@media (min-width: 768px) {
  .footer__link {
    font-size: 24px;
    line-height: 30px;
  }
  .footer__link-icon {
    width: 30px;
    height: 30px;
  }
  .footer__link-presentation {
    font-size: 15px;
  }
}
@media (min-width: 768px) {
  .footer {
    padding: 50px 0;
  }
}

.grid {
  display: flex;
  flex-flow: row wrap;
  margin: -12px;
  box-sizing: border-box;
}
.grid__el {
  width: 100%;
  min-height: 1px;
  min-width: 0;
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  padding: 12px;
  box-sizing: border-box;
}
.grid__el._filled {
  display: flex;
  flex-direction: column;
}
.grid__el._filled > * {
  flex: 1 0 auto;
}
.grid__el._start {
  align-self: flex-start;
}
.grid__el._center {
  align-self: center;
}
.grid__el._end {
  align-self: flex-end;
}
.grid__el._size-fluid {
  flex-basis: 0;
  flex-grow: 1;
}
.grid__el._size-auto {
  flex: 0 0 auto;
  width: auto;
}
.grid__el._order-first {
  order: -1;
}
.grid__el._order-last {
  order: 13;
}
.grid__el._size-1of5 {
  flex: 0 0 20%;
  max-width: 20%;
}
.grid__el._size-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}
.grid__el._size-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}
.grid__el._size-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.grid__el._size-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.grid__el._size-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}
.grid__el._size-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.grid__el._size-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}
.grid__el._size-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.grid__el._size-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.grid__el._size-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}
.grid__el._size-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}
.grid__el._size-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.grid__el._order-0 {
  order: 0;
}
.grid__el._order-1 {
  order: 1;
}
.grid__el._order-2 {
  order: 2;
}
.grid__el._order-3 {
  order: 3;
}
.grid__el._order-4 {
  order: 4;
}
.grid__el._order-5 {
  order: 5;
}
.grid__el._order-6 {
  order: 6;
}
.grid__el._order-7 {
  order: 7;
}
.grid__el._order-8 {
  order: 8;
}
.grid__el._order-9 {
  order: 9;
}
.grid__el._order-10 {
  order: 10;
}
.grid__el._order-11 {
  order: 11;
}
.grid__el._order-12 {
  order: 12;
}
.grid__el._offset-1 {
  margin-left: 8.3333333333%;
}
.grid__el._offset-2 {
  margin-left: 16.6666666667%;
}
.grid__el._offset-3 {
  margin-left: 25%;
}
.grid__el._offset-4 {
  margin-left: 33.3333333333%;
}
.grid__el._offset-5 {
  margin-left: 41.6666666667%;
}
.grid__el._offset-6 {
  margin-left: 50%;
}
.grid__el._offset-7 {
  margin-left: 58.3333333333%;
}
.grid__el._offset-8 {
  margin-left: 66.6666666667%;
}
.grid__el._offset-9 {
  margin-left: 75%;
}
.grid__el._offset-10 {
  margin-left: 83.3333333333%;
}
.grid__el._offset-11 {
  margin-left: 91.6666666667%;
}
.grid._row {
  flex-direction: row;
}
.grid._row-reverse {
  flex-direction: row-reverse;
}
.grid._column {
  flex-direction: column;
}
.grid._column-reverse {
  flex-direction: column-reverse;
}
.grid._column > .grid__el, .grid._column-reverse > .grid__el {
  flex-basis: auto;
}
.grid._justify-start {
  justify-content: flex-start;
}
.grid._justify-center {
  justify-content: center;
}
.grid._justify-end {
  justify-content: flex-end;
}
.grid._justify-between {
  justify-content: space-between;
}
.grid._justify-around {
  justify-content: space-around;
}
.grid._justify-evenly {
  justify-content: space-evenly;
}
.grid._items-start {
  align-items: flex-start;
}
.grid._items-center {
  align-items: center;
}
.grid._items-end {
  align-items: flex-end;
}
.grid._items-baseline {
  align-items: baseline;
}
.grid._items-stretch {
  align-items: stretch;
}
.grid._content-start {
  align-content: flex-start;
}
.grid._content-end {
  align-content: flex-end;
}
.grid._content-center {
  align-content: center;
}
.grid._content-between {
  align-content: space-between;
}
.grid._content-around {
  align-content: space-around;
}
.grid._content-evenly {
  align-content: space-evenly;
}
.grid._content-stretch {
  align-content: stretch;
}
.grid._nowrap {
  flex-wrap: nowrap;
}
.grid._wrap {
  flex-wrap: wrap;
}
.grid._wrap-reverse {
  flex-wrap: wrap-reverse;
}
.grid._space-none:not([class*=_space-v-]) {
  margin-top: 0;
  margin-bottom: 0;
}
.grid._space-none:not([class*=_space-h-]) {
  margin-left: 0;
  margin-right: 0;
}
.grid._space-none:not([class*=_space-v-]) > .grid__el {
  padding-top: 0;
  padding-bottom: 0;
}
.grid._space-none:not([class*=_space-h-]) > .grid__el {
  padding-left: 0;
  padding-right: 0;
}
.grid._space-v-none {
  margin-top: 0;
  margin-bottom: 0;
}
.grid._space-v-none > .grid__el {
  padding-top: 0;
  padding-bottom: 0;
}
.grid._space-h-none {
  margin-left: 0;
  margin-right: 0;
}
.grid._space-h-none > .grid__el {
  padding-left: 0;
  padding-right: 0;
}
.grid._space-mini:not([class*=_space-v-]) {
  margin-top: -4px;
  margin-bottom: -4px;
}
.grid._space-mini:not([class*=_space-h-]) {
  margin-left: -4px;
  margin-right: -4px;
}
.grid._space-mini:not([class*=_space-v-]) > .grid__el {
  padding-top: 4px;
  padding-bottom: 4px;
}
.grid._space-mini:not([class*=_space-h-]) > .grid__el {
  padding-left: 4px;
  padding-right: 4px;
}
.grid._space-v-mini {
  margin-top: -4px;
  margin-bottom: -4px;
}
.grid._space-v-mini > .grid__el {
  padding-top: 4px;
  padding-bottom: 4px;
}
.grid._space-h-mini {
  margin-left: -4px;
  margin-right: -4px;
}
.grid._space-h-mini > .grid__el {
  padding-left: 4px;
  padding-right: 4px;
}
.grid._space-small:not([class*=_space-v-]) {
  margin-top: -8px;
  margin-bottom: -8px;
}
.grid._space-small:not([class*=_space-h-]) {
  margin-left: -8px;
  margin-right: -8px;
}
.grid._space-small:not([class*=_space-v-]) > .grid__el {
  padding-top: 8px;
  padding-bottom: 8px;
}
.grid._space-small:not([class*=_space-h-]) > .grid__el {
  padding-left: 8px;
  padding-right: 8px;
}
.grid._space-v-small {
  margin-top: -8px;
  margin-bottom: -8px;
}
.grid._space-v-small > .grid__el {
  padding-top: 8px;
  padding-bottom: 8px;
}
.grid._space-h-small {
  margin-left: -8px;
  margin-right: -8px;
}
.grid._space-h-small > .grid__el {
  padding-left: 8px;
  padding-right: 8px;
}
.grid._space-base:not([class*=_space-v-]) {
  margin-top: -12px;
  margin-bottom: -12px;
}
.grid._space-base:not([class*=_space-h-]) {
  margin-left: -12px;
  margin-right: -12px;
}
.grid._space-base:not([class*=_space-v-]) > .grid__el {
  padding-top: 12px;
  padding-bottom: 12px;
}
.grid._space-base:not([class*=_space-h-]) > .grid__el {
  padding-left: 12px;
  padding-right: 12px;
}
.grid._space-v-base {
  margin-top: -12px;
  margin-bottom: -12px;
}
.grid._space-v-base > .grid__el {
  padding-top: 12px;
  padding-bottom: 12px;
}
.grid._space-h-base {
  margin-left: -12px;
  margin-right: -12px;
}
.grid._space-h-base > .grid__el {
  padding-left: 12px;
  padding-right: 12px;
}
.grid._space-medium:not([class*=_space-v-]) {
  margin-top: -18px;
  margin-bottom: -18px;
}
.grid._space-medium:not([class*=_space-h-]) {
  margin-left: -18px;
  margin-right: -18px;
}
.grid._space-medium:not([class*=_space-v-]) > .grid__el {
  padding-top: 18px;
  padding-bottom: 18px;
}
.grid._space-medium:not([class*=_space-h-]) > .grid__el {
  padding-left: 18px;
  padding-right: 18px;
}
.grid._space-v-medium {
  margin-top: -18px;
  margin-bottom: -18px;
}
.grid._space-v-medium > .grid__el {
  padding-top: 18px;
  padding-bottom: 18px;
}
.grid._space-h-medium {
  margin-left: -18px;
  margin-right: -18px;
}
.grid._space-h-medium > .grid__el {
  padding-left: 18px;
  padding-right: 18px;
}
@media (min-width: 560px) {
  .grid__el._sm-filled {
    display: flex;
    flex-direction: column;
  }
  .grid__el._sm-filled > * {
    flex: 1 0 auto;
  }
  .grid__el._sm-start {
    align-self: flex-start;
  }
  .grid__el._sm-center {
    align-self: center;
  }
  .grid__el._sm-end {
    align-self: flex-end;
  }
  .grid__el._sm-size-fluid {
    flex-basis: 0;
    flex-grow: 1;
  }
  .grid__el._sm-size-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .grid__el._sm-order-first {
    order: -1;
  }
  .grid__el._sm-order-last {
    order: 13;
  }
  .grid__el._sm-size-1of5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .grid__el._sm-size-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .grid__el._sm-size-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .grid__el._sm-size-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid__el._sm-size-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .grid__el._sm-size-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .grid__el._sm-size-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid__el._sm-size-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .grid__el._sm-size-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .grid__el._sm-size-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .grid__el._sm-size-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .grid__el._sm-size-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .grid__el._sm-size-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid__el._sm-order-0 {
    order: 0;
  }
  .grid__el._sm-order-1 {
    order: 1;
  }
  .grid__el._sm-order-2 {
    order: 2;
  }
  .grid__el._sm-order-3 {
    order: 3;
  }
  .grid__el._sm-order-4 {
    order: 4;
  }
  .grid__el._sm-order-5 {
    order: 5;
  }
  .grid__el._sm-order-6 {
    order: 6;
  }
  .grid__el._sm-order-7 {
    order: 7;
  }
  .grid__el._sm-order-8 {
    order: 8;
  }
  .grid__el._sm-order-9 {
    order: 9;
  }
  .grid__el._sm-order-10 {
    order: 10;
  }
  .grid__el._sm-order-11 {
    order: 11;
  }
  .grid__el._sm-order-12 {
    order: 12;
  }
  .grid__el._sm-offset-0 {
    margin-left: 0;
  }
  .grid__el._sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .grid__el._sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .grid__el._sm-offset-3 {
    margin-left: 25%;
  }
  .grid__el._sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .grid__el._sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .grid__el._sm-offset-6 {
    margin-left: 50%;
  }
  .grid__el._sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .grid__el._sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .grid__el._sm-offset-9 {
    margin-left: 75%;
  }
  .grid__el._sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .grid__el._sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .grid._sm-row {
    flex-direction: row;
  }
  .grid._sm-row-reverse {
    flex-direction: row-reverse;
  }
  .grid._sm-column {
    flex-direction: column;
  }
  .grid._sm-column-reverse {
    flex-direction: column-reverse;
  }
  .grid._sm-column > .grid__el, .grid._sm-column-reverse > .grid__el {
    flex-basis: auto;
  }
  .grid._sm-justify-start {
    justify-content: flex-start;
  }
  .grid._sm-justify-center {
    justify-content: center;
  }
  .grid._sm-justify-end {
    justify-content: flex-end;
  }
  .grid._sm-justify-between {
    justify-content: space-between;
  }
  .grid._sm-justify-around {
    justify-content: space-around;
  }
  .grid._sm-justify-evenly {
    justify-content: space-evenly;
  }
  .grid._sm-items-start {
    align-items: flex-start;
  }
  .grid._sm-items-center {
    align-items: center;
  }
  .grid._sm-items-end {
    align-items: flex-end;
  }
  .grid._sm-items-baseline {
    align-items: baseline;
  }
  .grid._sm-items-stretch {
    align-items: stretch;
  }
  .grid._sm-content-start {
    align-content: flex-start;
  }
  .grid._sm-content-end {
    align-content: flex-end;
  }
  .grid._sm-content-center {
    align-content: center;
  }
  .grid._sm-content-between {
    align-content: space-between;
  }
  .grid._sm-content-around {
    align-content: space-around;
  }
  .grid._sm-content-evenly {
    align-content: space-evenly;
  }
  .grid._sm-content-stretch {
    align-content: stretch;
  }
  .grid._sm-nowrap {
    flex-wrap: nowrap;
  }
  .grid._sm-wrap {
    flex-wrap: wrap;
  }
  .grid._sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .grid._sm-space-none:not([class*=_sm-space-v-]) {
    margin-top: 0;
    margin-bottom: 0;
  }
  .grid._sm-space-none:not([class*=_sm-space-h-]) {
    margin-left: 0;
    margin-right: 0;
  }
  .grid._sm-space-none:not([class*=_sm-space-v-]) > .grid__el {
    padding-top: 0;
    padding-bottom: 0;
  }
  .grid._sm-space-none:not([class*=_sm-space-h-]) > .grid__el {
    padding-left: 0;
    padding-right: 0;
  }
  .grid._sm-space-v-none {
    margin-top: 0;
    margin-bottom: 0;
  }
  .grid._sm-space-v-none > .grid__el {
    padding-top: 0;
    padding-bottom: 0;
  }
  .grid._sm-space-h-none {
    margin-left: 0;
    margin-right: 0;
  }
  .grid._sm-space-h-none > .grid__el {
    padding-left: 0;
    padding-right: 0;
  }
  .grid._sm-space-mini:not([class*=_sm-space-v-]) {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  .grid._sm-space-mini:not([class*=_sm-space-h-]) {
    margin-left: -4px;
    margin-right: -4px;
  }
  .grid._sm-space-mini:not([class*=_sm-space-v-]) > .grid__el {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .grid._sm-space-mini:not([class*=_sm-space-h-]) > .grid__el {
    padding-left: 4px;
    padding-right: 4px;
  }
  .grid._sm-space-v-mini {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  .grid._sm-space-v-mini > .grid__el {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .grid._sm-space-h-mini {
    margin-left: -4px;
    margin-right: -4px;
  }
  .grid._sm-space-h-mini > .grid__el {
    padding-left: 4px;
    padding-right: 4px;
  }
  .grid._sm-space-small:not([class*=_sm-space-v-]) {
    margin-top: -8px;
    margin-bottom: -8px;
  }
  .grid._sm-space-small:not([class*=_sm-space-h-]) {
    margin-left: -8px;
    margin-right: -8px;
  }
  .grid._sm-space-small:not([class*=_sm-space-v-]) > .grid__el {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .grid._sm-space-small:not([class*=_sm-space-h-]) > .grid__el {
    padding-left: 8px;
    padding-right: 8px;
  }
  .grid._sm-space-v-small {
    margin-top: -8px;
    margin-bottom: -8px;
  }
  .grid._sm-space-v-small > .grid__el {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .grid._sm-space-h-small {
    margin-left: -8px;
    margin-right: -8px;
  }
  .grid._sm-space-h-small > .grid__el {
    padding-left: 8px;
    padding-right: 8px;
  }
  .grid._sm-space-base:not([class*=_sm-space-v-]) {
    margin-top: -12px;
    margin-bottom: -12px;
  }
  .grid._sm-space-base:not([class*=_sm-space-h-]) {
    margin-left: -12px;
    margin-right: -12px;
  }
  .grid._sm-space-base:not([class*=_sm-space-v-]) > .grid__el {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .grid._sm-space-base:not([class*=_sm-space-h-]) > .grid__el {
    padding-left: 12px;
    padding-right: 12px;
  }
  .grid._sm-space-v-base {
    margin-top: -12px;
    margin-bottom: -12px;
  }
  .grid._sm-space-v-base > .grid__el {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .grid._sm-space-h-base {
    margin-left: -12px;
    margin-right: -12px;
  }
  .grid._sm-space-h-base > .grid__el {
    padding-left: 12px;
    padding-right: 12px;
  }
  .grid._sm-space-medium:not([class*=_sm-space-v-]) {
    margin-top: -18px;
    margin-bottom: -18px;
  }
  .grid._sm-space-medium:not([class*=_sm-space-h-]) {
    margin-left: -18px;
    margin-right: -18px;
  }
  .grid._sm-space-medium:not([class*=_sm-space-v-]) > .grid__el {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .grid._sm-space-medium:not([class*=_sm-space-h-]) > .grid__el {
    padding-left: 18px;
    padding-right: 18px;
  }
  .grid._sm-space-v-medium {
    margin-top: -18px;
    margin-bottom: -18px;
  }
  .grid._sm-space-v-medium > .grid__el {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .grid._sm-space-h-medium {
    margin-left: -18px;
    margin-right: -18px;
  }
  .grid._sm-space-h-medium > .grid__el {
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media (min-width: 768px) {
  .grid__el._md-filled {
    display: flex;
    flex-direction: column;
  }
  .grid__el._md-filled > * {
    flex: 1 0 auto;
  }
  .grid__el._md-start {
    align-self: flex-start;
  }
  .grid__el._md-center {
    align-self: center;
  }
  .grid__el._md-end {
    align-self: flex-end;
  }
  .grid__el._md-size-fluid {
    flex-basis: 0;
    flex-grow: 1;
  }
  .grid__el._md-size-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .grid__el._md-order-first {
    order: -1;
  }
  .grid__el._md-order-last {
    order: 13;
  }
  .grid__el._md-size-1of5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .grid__el._md-size-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .grid__el._md-size-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .grid__el._md-size-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid__el._md-size-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .grid__el._md-size-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .grid__el._md-size-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid__el._md-size-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .grid__el._md-size-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .grid__el._md-size-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .grid__el._md-size-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .grid__el._md-size-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .grid__el._md-size-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid__el._md-order-0 {
    order: 0;
  }
  .grid__el._md-order-1 {
    order: 1;
  }
  .grid__el._md-order-2 {
    order: 2;
  }
  .grid__el._md-order-3 {
    order: 3;
  }
  .grid__el._md-order-4 {
    order: 4;
  }
  .grid__el._md-order-5 {
    order: 5;
  }
  .grid__el._md-order-6 {
    order: 6;
  }
  .grid__el._md-order-7 {
    order: 7;
  }
  .grid__el._md-order-8 {
    order: 8;
  }
  .grid__el._md-order-9 {
    order: 9;
  }
  .grid__el._md-order-10 {
    order: 10;
  }
  .grid__el._md-order-11 {
    order: 11;
  }
  .grid__el._md-order-12 {
    order: 12;
  }
  .grid__el._md-offset-0 {
    margin-left: 0;
  }
  .grid__el._md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .grid__el._md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .grid__el._md-offset-3 {
    margin-left: 25%;
  }
  .grid__el._md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .grid__el._md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .grid__el._md-offset-6 {
    margin-left: 50%;
  }
  .grid__el._md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .grid__el._md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .grid__el._md-offset-9 {
    margin-left: 75%;
  }
  .grid__el._md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .grid__el._md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .grid._md-row {
    flex-direction: row;
  }
  .grid._md-row-reverse {
    flex-direction: row-reverse;
  }
  .grid._md-column {
    flex-direction: column;
  }
  .grid._md-column-reverse {
    flex-direction: column-reverse;
  }
  .grid._md-column > .grid__el, .grid._md-column-reverse > .grid__el {
    flex-basis: auto;
  }
  .grid._md-justify-start {
    justify-content: flex-start;
  }
  .grid._md-justify-center {
    justify-content: center;
  }
  .grid._md-justify-end {
    justify-content: flex-end;
  }
  .grid._md-justify-between {
    justify-content: space-between;
  }
  .grid._md-justify-around {
    justify-content: space-around;
  }
  .grid._md-justify-evenly {
    justify-content: space-evenly;
  }
  .grid._md-items-start {
    align-items: flex-start;
  }
  .grid._md-items-center {
    align-items: center;
  }
  .grid._md-items-end {
    align-items: flex-end;
  }
  .grid._md-items-baseline {
    align-items: baseline;
  }
  .grid._md-items-stretch {
    align-items: stretch;
  }
  .grid._md-content-start {
    align-content: flex-start;
  }
  .grid._md-content-end {
    align-content: flex-end;
  }
  .grid._md-content-center {
    align-content: center;
  }
  .grid._md-content-between {
    align-content: space-between;
  }
  .grid._md-content-around {
    align-content: space-around;
  }
  .grid._md-content-evenly {
    align-content: space-evenly;
  }
  .grid._md-content-stretch {
    align-content: stretch;
  }
  .grid._md-nowrap {
    flex-wrap: nowrap;
  }
  .grid._md-wrap {
    flex-wrap: wrap;
  }
  .grid._md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .grid._md-space-none:not([class*=_md-space-v-]) {
    margin-top: 0;
    margin-bottom: 0;
  }
  .grid._md-space-none:not([class*=_md-space-h-]) {
    margin-left: 0;
    margin-right: 0;
  }
  .grid._md-space-none:not([class*=_md-space-v-]) > .grid__el {
    padding-top: 0;
    padding-bottom: 0;
  }
  .grid._md-space-none:not([class*=_md-space-h-]) > .grid__el {
    padding-left: 0;
    padding-right: 0;
  }
  .grid._md-space-v-none {
    margin-top: 0;
    margin-bottom: 0;
  }
  .grid._md-space-v-none > .grid__el {
    padding-top: 0;
    padding-bottom: 0;
  }
  .grid._md-space-h-none {
    margin-left: 0;
    margin-right: 0;
  }
  .grid._md-space-h-none > .grid__el {
    padding-left: 0;
    padding-right: 0;
  }
  .grid._md-space-mini:not([class*=_md-space-v-]) {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  .grid._md-space-mini:not([class*=_md-space-h-]) {
    margin-left: -4px;
    margin-right: -4px;
  }
  .grid._md-space-mini:not([class*=_md-space-v-]) > .grid__el {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .grid._md-space-mini:not([class*=_md-space-h-]) > .grid__el {
    padding-left: 4px;
    padding-right: 4px;
  }
  .grid._md-space-v-mini {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  .grid._md-space-v-mini > .grid__el {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .grid._md-space-h-mini {
    margin-left: -4px;
    margin-right: -4px;
  }
  .grid._md-space-h-mini > .grid__el {
    padding-left: 4px;
    padding-right: 4px;
  }
  .grid._md-space-small:not([class*=_md-space-v-]) {
    margin-top: -8px;
    margin-bottom: -8px;
  }
  .grid._md-space-small:not([class*=_md-space-h-]) {
    margin-left: -8px;
    margin-right: -8px;
  }
  .grid._md-space-small:not([class*=_md-space-v-]) > .grid__el {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .grid._md-space-small:not([class*=_md-space-h-]) > .grid__el {
    padding-left: 8px;
    padding-right: 8px;
  }
  .grid._md-space-v-small {
    margin-top: -8px;
    margin-bottom: -8px;
  }
  .grid._md-space-v-small > .grid__el {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .grid._md-space-h-small {
    margin-left: -8px;
    margin-right: -8px;
  }
  .grid._md-space-h-small > .grid__el {
    padding-left: 8px;
    padding-right: 8px;
  }
  .grid._md-space-base:not([class*=_md-space-v-]) {
    margin-top: -12px;
    margin-bottom: -12px;
  }
  .grid._md-space-base:not([class*=_md-space-h-]) {
    margin-left: -12px;
    margin-right: -12px;
  }
  .grid._md-space-base:not([class*=_md-space-v-]) > .grid__el {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .grid._md-space-base:not([class*=_md-space-h-]) > .grid__el {
    padding-left: 12px;
    padding-right: 12px;
  }
  .grid._md-space-v-base {
    margin-top: -12px;
    margin-bottom: -12px;
  }
  .grid._md-space-v-base > .grid__el {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .grid._md-space-h-base {
    margin-left: -12px;
    margin-right: -12px;
  }
  .grid._md-space-h-base > .grid__el {
    padding-left: 12px;
    padding-right: 12px;
  }
  .grid._md-space-medium:not([class*=_md-space-v-]) {
    margin-top: -18px;
    margin-bottom: -18px;
  }
  .grid._md-space-medium:not([class*=_md-space-h-]) {
    margin-left: -18px;
    margin-right: -18px;
  }
  .grid._md-space-medium:not([class*=_md-space-v-]) > .grid__el {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .grid._md-space-medium:not([class*=_md-space-h-]) > .grid__el {
    padding-left: 18px;
    padding-right: 18px;
  }
  .grid._md-space-v-medium {
    margin-top: -18px;
    margin-bottom: -18px;
  }
  .grid._md-space-v-medium > .grid__el {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .grid._md-space-h-medium {
    margin-left: -18px;
    margin-right: -18px;
  }
  .grid._md-space-h-medium > .grid__el {
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media (min-width: 992px) {
  .grid__el._lg-filled {
    display: flex;
    flex-direction: column;
  }
  .grid__el._lg-filled > * {
    flex: 1 0 auto;
  }
  .grid__el._lg-start {
    align-self: flex-start;
  }
  .grid__el._lg-center {
    align-self: center;
  }
  .grid__el._lg-end {
    align-self: flex-end;
  }
  .grid__el._lg-size-fluid {
    flex-basis: 0;
    flex-grow: 1;
  }
  .grid__el._lg-size-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .grid__el._lg-order-first {
    order: -1;
  }
  .grid__el._lg-order-last {
    order: 13;
  }
  .grid__el._lg-size-1of5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .grid__el._lg-size-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .grid__el._lg-size-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .grid__el._lg-size-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid__el._lg-size-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .grid__el._lg-size-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .grid__el._lg-size-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid__el._lg-size-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .grid__el._lg-size-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .grid__el._lg-size-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .grid__el._lg-size-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .grid__el._lg-size-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .grid__el._lg-size-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid__el._lg-order-0 {
    order: 0;
  }
  .grid__el._lg-order-1 {
    order: 1;
  }
  .grid__el._lg-order-2 {
    order: 2;
  }
  .grid__el._lg-order-3 {
    order: 3;
  }
  .grid__el._lg-order-4 {
    order: 4;
  }
  .grid__el._lg-order-5 {
    order: 5;
  }
  .grid__el._lg-order-6 {
    order: 6;
  }
  .grid__el._lg-order-7 {
    order: 7;
  }
  .grid__el._lg-order-8 {
    order: 8;
  }
  .grid__el._lg-order-9 {
    order: 9;
  }
  .grid__el._lg-order-10 {
    order: 10;
  }
  .grid__el._lg-order-11 {
    order: 11;
  }
  .grid__el._lg-order-12 {
    order: 12;
  }
  .grid__el._lg-offset-0 {
    margin-left: 0;
  }
  .grid__el._lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .grid__el._lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .grid__el._lg-offset-3 {
    margin-left: 25%;
  }
  .grid__el._lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .grid__el._lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .grid__el._lg-offset-6 {
    margin-left: 50%;
  }
  .grid__el._lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .grid__el._lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .grid__el._lg-offset-9 {
    margin-left: 75%;
  }
  .grid__el._lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .grid__el._lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .grid._lg-row {
    flex-direction: row;
  }
  .grid._lg-row-reverse {
    flex-direction: row-reverse;
  }
  .grid._lg-column {
    flex-direction: column;
  }
  .grid._lg-column-reverse {
    flex-direction: column-reverse;
  }
  .grid._lg-column > .grid__el, .grid._lg-column-reverse > .grid__el {
    flex-basis: auto;
  }
  .grid._lg-justify-start {
    justify-content: flex-start;
  }
  .grid._lg-justify-center {
    justify-content: center;
  }
  .grid._lg-justify-end {
    justify-content: flex-end;
  }
  .grid._lg-justify-between {
    justify-content: space-between;
  }
  .grid._lg-justify-around {
    justify-content: space-around;
  }
  .grid._lg-justify-evenly {
    justify-content: space-evenly;
  }
  .grid._lg-items-start {
    align-items: flex-start;
  }
  .grid._lg-items-center {
    align-items: center;
  }
  .grid._lg-items-end {
    align-items: flex-end;
  }
  .grid._lg-items-baseline {
    align-items: baseline;
  }
  .grid._lg-items-stretch {
    align-items: stretch;
  }
  .grid._lg-content-start {
    align-content: flex-start;
  }
  .grid._lg-content-end {
    align-content: flex-end;
  }
  .grid._lg-content-center {
    align-content: center;
  }
  .grid._lg-content-between {
    align-content: space-between;
  }
  .grid._lg-content-around {
    align-content: space-around;
  }
  .grid._lg-content-evenly {
    align-content: space-evenly;
  }
  .grid._lg-content-stretch {
    align-content: stretch;
  }
  .grid._lg-nowrap {
    flex-wrap: nowrap;
  }
  .grid._lg-wrap {
    flex-wrap: wrap;
  }
  .grid._lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .grid._lg-space-none:not([class*=_lg-space-v-]) {
    margin-top: 0;
    margin-bottom: 0;
  }
  .grid._lg-space-none:not([class*=_lg-space-h-]) {
    margin-left: 0;
    margin-right: 0;
  }
  .grid._lg-space-none:not([class*=_lg-space-v-]) > .grid__el {
    padding-top: 0;
    padding-bottom: 0;
  }
  .grid._lg-space-none:not([class*=_lg-space-h-]) > .grid__el {
    padding-left: 0;
    padding-right: 0;
  }
  .grid._lg-space-v-none {
    margin-top: 0;
    margin-bottom: 0;
  }
  .grid._lg-space-v-none > .grid__el {
    padding-top: 0;
    padding-bottom: 0;
  }
  .grid._lg-space-h-none {
    margin-left: 0;
    margin-right: 0;
  }
  .grid._lg-space-h-none > .grid__el {
    padding-left: 0;
    padding-right: 0;
  }
  .grid._lg-space-mini:not([class*=_lg-space-v-]) {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  .grid._lg-space-mini:not([class*=_lg-space-h-]) {
    margin-left: -4px;
    margin-right: -4px;
  }
  .grid._lg-space-mini:not([class*=_lg-space-v-]) > .grid__el {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .grid._lg-space-mini:not([class*=_lg-space-h-]) > .grid__el {
    padding-left: 4px;
    padding-right: 4px;
  }
  .grid._lg-space-v-mini {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  .grid._lg-space-v-mini > .grid__el {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .grid._lg-space-h-mini {
    margin-left: -4px;
    margin-right: -4px;
  }
  .grid._lg-space-h-mini > .grid__el {
    padding-left: 4px;
    padding-right: 4px;
  }
  .grid._lg-space-small:not([class*=_lg-space-v-]) {
    margin-top: -8px;
    margin-bottom: -8px;
  }
  .grid._lg-space-small:not([class*=_lg-space-h-]) {
    margin-left: -8px;
    margin-right: -8px;
  }
  .grid._lg-space-small:not([class*=_lg-space-v-]) > .grid__el {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .grid._lg-space-small:not([class*=_lg-space-h-]) > .grid__el {
    padding-left: 8px;
    padding-right: 8px;
  }
  .grid._lg-space-v-small {
    margin-top: -8px;
    margin-bottom: -8px;
  }
  .grid._lg-space-v-small > .grid__el {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .grid._lg-space-h-small {
    margin-left: -8px;
    margin-right: -8px;
  }
  .grid._lg-space-h-small > .grid__el {
    padding-left: 8px;
    padding-right: 8px;
  }
  .grid._lg-space-base:not([class*=_lg-space-v-]) {
    margin-top: -12px;
    margin-bottom: -12px;
  }
  .grid._lg-space-base:not([class*=_lg-space-h-]) {
    margin-left: -12px;
    margin-right: -12px;
  }
  .grid._lg-space-base:not([class*=_lg-space-v-]) > .grid__el {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .grid._lg-space-base:not([class*=_lg-space-h-]) > .grid__el {
    padding-left: 12px;
    padding-right: 12px;
  }
  .grid._lg-space-v-base {
    margin-top: -12px;
    margin-bottom: -12px;
  }
  .grid._lg-space-v-base > .grid__el {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .grid._lg-space-h-base {
    margin-left: -12px;
    margin-right: -12px;
  }
  .grid._lg-space-h-base > .grid__el {
    padding-left: 12px;
    padding-right: 12px;
  }
  .grid._lg-space-medium:not([class*=_lg-space-v-]) {
    margin-top: -18px;
    margin-bottom: -18px;
  }
  .grid._lg-space-medium:not([class*=_lg-space-h-]) {
    margin-left: -18px;
    margin-right: -18px;
  }
  .grid._lg-space-medium:not([class*=_lg-space-v-]) > .grid__el {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .grid._lg-space-medium:not([class*=_lg-space-h-]) > .grid__el {
    padding-left: 18px;
    padding-right: 18px;
  }
  .grid._lg-space-v-medium {
    margin-top: -18px;
    margin-bottom: -18px;
  }
  .grid._lg-space-v-medium > .grid__el {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .grid._lg-space-h-medium {
    margin-left: -18px;
    margin-right: -18px;
  }
  .grid._lg-space-h-medium > .grid__el {
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media (min-width: 1200px) {
  .grid__el._xl-filled {
    display: flex;
    flex-direction: column;
  }
  .grid__el._xl-filled > * {
    flex: 1 0 auto;
  }
  .grid__el._xl-start {
    align-self: flex-start;
  }
  .grid__el._xl-center {
    align-self: center;
  }
  .grid__el._xl-end {
    align-self: flex-end;
  }
  .grid__el._xl-size-fluid {
    flex-basis: 0;
    flex-grow: 1;
  }
  .grid__el._xl-size-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .grid__el._xl-order-first {
    order: -1;
  }
  .grid__el._xl-order-last {
    order: 13;
  }
  .grid__el._xl-size-1of5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .grid__el._xl-size-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .grid__el._xl-size-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .grid__el._xl-size-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .grid__el._xl-size-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .grid__el._xl-size-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .grid__el._xl-size-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grid__el._xl-size-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .grid__el._xl-size-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .grid__el._xl-size-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .grid__el._xl-size-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .grid__el._xl-size-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .grid__el._xl-size-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grid__el._xl-order-0 {
    order: 0;
  }
  .grid__el._xl-order-1 {
    order: 1;
  }
  .grid__el._xl-order-2 {
    order: 2;
  }
  .grid__el._xl-order-3 {
    order: 3;
  }
  .grid__el._xl-order-4 {
    order: 4;
  }
  .grid__el._xl-order-5 {
    order: 5;
  }
  .grid__el._xl-order-6 {
    order: 6;
  }
  .grid__el._xl-order-7 {
    order: 7;
  }
  .grid__el._xl-order-8 {
    order: 8;
  }
  .grid__el._xl-order-9 {
    order: 9;
  }
  .grid__el._xl-order-10 {
    order: 10;
  }
  .grid__el._xl-order-11 {
    order: 11;
  }
  .grid__el._xl-order-12 {
    order: 12;
  }
  .grid__el._xl-offset-0 {
    margin-left: 0;
  }
  .grid__el._xl-offset-1 {
    margin-left: 8.3333333333%;
  }
  .grid__el._xl-offset-2 {
    margin-left: 16.6666666667%;
  }
  .grid__el._xl-offset-3 {
    margin-left: 25%;
  }
  .grid__el._xl-offset-4 {
    margin-left: 33.3333333333%;
  }
  .grid__el._xl-offset-5 {
    margin-left: 41.6666666667%;
  }
  .grid__el._xl-offset-6 {
    margin-left: 50%;
  }
  .grid__el._xl-offset-7 {
    margin-left: 58.3333333333%;
  }
  .grid__el._xl-offset-8 {
    margin-left: 66.6666666667%;
  }
  .grid__el._xl-offset-9 {
    margin-left: 75%;
  }
  .grid__el._xl-offset-10 {
    margin-left: 83.3333333333%;
  }
  .grid__el._xl-offset-11 {
    margin-left: 91.6666666667%;
  }
  .grid._xl-row {
    flex-direction: row;
  }
  .grid._xl-row-reverse {
    flex-direction: row-reverse;
  }
  .grid._xl-column {
    flex-direction: column;
  }
  .grid._xl-column-reverse {
    flex-direction: column-reverse;
  }
  .grid._xl-column > .grid__el, .grid._xl-column-reverse > .grid__el {
    flex-basis: auto;
  }
  .grid._xl-justify-start {
    justify-content: flex-start;
  }
  .grid._xl-justify-center {
    justify-content: center;
  }
  .grid._xl-justify-end {
    justify-content: flex-end;
  }
  .grid._xl-justify-between {
    justify-content: space-between;
  }
  .grid._xl-justify-around {
    justify-content: space-around;
  }
  .grid._xl-justify-evenly {
    justify-content: space-evenly;
  }
  .grid._xl-items-start {
    align-items: flex-start;
  }
  .grid._xl-items-center {
    align-items: center;
  }
  .grid._xl-items-end {
    align-items: flex-end;
  }
  .grid._xl-items-baseline {
    align-items: baseline;
  }
  .grid._xl-items-stretch {
    align-items: stretch;
  }
  .grid._xl-content-start {
    align-content: flex-start;
  }
  .grid._xl-content-end {
    align-content: flex-end;
  }
  .grid._xl-content-center {
    align-content: center;
  }
  .grid._xl-content-between {
    align-content: space-between;
  }
  .grid._xl-content-around {
    align-content: space-around;
  }
  .grid._xl-content-evenly {
    align-content: space-evenly;
  }
  .grid._xl-content-stretch {
    align-content: stretch;
  }
  .grid._xl-nowrap {
    flex-wrap: nowrap;
  }
  .grid._xl-wrap {
    flex-wrap: wrap;
  }
  .grid._xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .grid._xl-space-none:not([class*=_xl-space-v-]) {
    margin-top: 0;
    margin-bottom: 0;
  }
  .grid._xl-space-none:not([class*=_xl-space-h-]) {
    margin-left: 0;
    margin-right: 0;
  }
  .grid._xl-space-none:not([class*=_xl-space-v-]) > .grid__el {
    padding-top: 0;
    padding-bottom: 0;
  }
  .grid._xl-space-none:not([class*=_xl-space-h-]) > .grid__el {
    padding-left: 0;
    padding-right: 0;
  }
  .grid._xl-space-v-none {
    margin-top: 0;
    margin-bottom: 0;
  }
  .grid._xl-space-v-none > .grid__el {
    padding-top: 0;
    padding-bottom: 0;
  }
  .grid._xl-space-h-none {
    margin-left: 0;
    margin-right: 0;
  }
  .grid._xl-space-h-none > .grid__el {
    padding-left: 0;
    padding-right: 0;
  }
  .grid._xl-space-mini:not([class*=_xl-space-v-]) {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  .grid._xl-space-mini:not([class*=_xl-space-h-]) {
    margin-left: -4px;
    margin-right: -4px;
  }
  .grid._xl-space-mini:not([class*=_xl-space-v-]) > .grid__el {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .grid._xl-space-mini:not([class*=_xl-space-h-]) > .grid__el {
    padding-left: 4px;
    padding-right: 4px;
  }
  .grid._xl-space-v-mini {
    margin-top: -4px;
    margin-bottom: -4px;
  }
  .grid._xl-space-v-mini > .grid__el {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .grid._xl-space-h-mini {
    margin-left: -4px;
    margin-right: -4px;
  }
  .grid._xl-space-h-mini > .grid__el {
    padding-left: 4px;
    padding-right: 4px;
  }
  .grid._xl-space-small:not([class*=_xl-space-v-]) {
    margin-top: -8px;
    margin-bottom: -8px;
  }
  .grid._xl-space-small:not([class*=_xl-space-h-]) {
    margin-left: -8px;
    margin-right: -8px;
  }
  .grid._xl-space-small:not([class*=_xl-space-v-]) > .grid__el {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .grid._xl-space-small:not([class*=_xl-space-h-]) > .grid__el {
    padding-left: 8px;
    padding-right: 8px;
  }
  .grid._xl-space-v-small {
    margin-top: -8px;
    margin-bottom: -8px;
  }
  .grid._xl-space-v-small > .grid__el {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .grid._xl-space-h-small {
    margin-left: -8px;
    margin-right: -8px;
  }
  .grid._xl-space-h-small > .grid__el {
    padding-left: 8px;
    padding-right: 8px;
  }
  .grid._xl-space-base:not([class*=_xl-space-v-]) {
    margin-top: -12px;
    margin-bottom: -12px;
  }
  .grid._xl-space-base:not([class*=_xl-space-h-]) {
    margin-left: -12px;
    margin-right: -12px;
  }
  .grid._xl-space-base:not([class*=_xl-space-v-]) > .grid__el {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .grid._xl-space-base:not([class*=_xl-space-h-]) > .grid__el {
    padding-left: 12px;
    padding-right: 12px;
  }
  .grid._xl-space-v-base {
    margin-top: -12px;
    margin-bottom: -12px;
  }
  .grid._xl-space-v-base > .grid__el {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .grid._xl-space-h-base {
    margin-left: -12px;
    margin-right: -12px;
  }
  .grid._xl-space-h-base > .grid__el {
    padding-left: 12px;
    padding-right: 12px;
  }
  .grid._xl-space-medium:not([class*=_xl-space-v-]) {
    margin-top: -18px;
    margin-bottom: -18px;
  }
  .grid._xl-space-medium:not([class*=_xl-space-h-]) {
    margin-left: -18px;
    margin-right: -18px;
  }
  .grid._xl-space-medium:not([class*=_xl-space-v-]) > .grid__el {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .grid._xl-space-medium:not([class*=_xl-space-h-]) > .grid__el {
    padding-left: 18px;
    padding-right: 18px;
  }
  .grid._xl-space-v-medium {
    margin-top: -18px;
    margin-bottom: -18px;
  }
  .grid._xl-space-v-medium > .grid__el {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .grid._xl-space-h-medium {
    margin-left: -18px;
    margin-right: -18px;
  }
  .grid._xl-space-h-medium > .grid__el {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.header {
  display: flex;
  padding: 20px 0;
}
.header__logo {
  display: block;
  text-decoration: none;
  width: 144px;
  height: 36px;
}
.header__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (min-width: 768px) {
  .header__logo {
    width: 203px;
    height: 50px;
  }
}
.header__mail {
  display: inline-flex;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15em;
  color: #696969;
  text-decoration: none;
  transition: 0.1s opacity ease-in-out;
}
.header__mail:hover {
  opacity: 0.75;
}
.header__mail-icon {
  width: 20px;
  height: 20px;
  fill: #dbdbdb;
  margin-right: 12px;
}
@media (min-width: 768px) {
  .header__mail {
    font-size: 24px;
    line-height: 30px;
  }
  .header__mail-icon {
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .header {
    padding: 30px 0;
  }
}

.main-section {
  max-width: 1920px;
  margin: 0 auto;
}
.main-section__inner {
  background-image: url("../assets/images/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.main-section__images, .main-section__container {
  position: relative;
}
.main-section__container {
  min-height: 150px;
  margin-top: 40px;
}
@media (min-width: 768px) {
  .main-section__container {
    min-height: 250px;
    margin-top: 80px;
  }
}
@media (min-width: 1200px) {
  .main-section__container {
    min-height: 0;
  }
}
.main-section__cover {
  width: 200%;
  margin-top: -25%;
  margin-left: -80%;
}
@media (min-width: 560px) {
  .main-section__cover {
    width: 100%;
    margin-left: 0;
    margin-top: -15%;
  }
}
@media (min-width: 768px) {
  .main-section__cover {
    margin-top: -20%;
  }
}
@media (min-width: 1200px) {
  .main-section__cover {
    margin-top: 0;
  }
}
.main-section__box {
  position: absolute;
  bottom: 0;
  right: -15px;
  width: 90%;
}
@media (min-width: 560px) {
  .main-section__box {
    width: 45%;
    right: 8%;
  }
}
@media (min-width: 1200px) {
  .main-section__box {
    bottom: auto;
    top: 0;
  }
}
.main-section__title {
  position: relative;
  z-index: 2;
}
@media (min-width: 1200px) {
  .main-section__title {
    position: absolute;
    top: 48px;
    left: 10px;
  }
}
@media (max-width: 559.98px) {
  .main-section {
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  .main-section {
    height: 100vh;
  }
}

.page {
  padding-bottom: 50px;
}
.page__section + .page__section {
  margin-top: 30px;
}
@media (min-width: 768px) {
  .page__section + .page__section {
    margin-top: 60px;
  }
}
.page__section-title {
  font-weight: 800;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #1475f3;
  margin: 0 0 25px;
}
@media (min-width: 768px) {
  .page__section-title {
    font-size: 34px;
    line-height: 55px;
    margin-bottom: 50px;
  }
}
@media (min-width: 768px) {
  .page {
    padding-bottom: 175px;
  }
}

.switch-link {
  text-decoration: none;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #262626;
  padding: 5px 12px 5px 14px;
  border: 1px solid transparent;
  border-radius: 56px;
}
.switch-link:hover {
  border-color: #ececec;
}
.switch-link._active {
  pointer-events: none;
  color: #949494;
  border-color: #dadada;
}